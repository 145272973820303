$margin : 10px;
$padding: 10px;
$border-radius : 5px;
$color-white : #ffffff;
$color-black : #000000;
$background-color-purple : #8000e2;
$background-color-purple-light: #f8eeff;
$background-color-red : #b00000;
$background-color-blue : #0065a3;
$background-color-grey : #eeeeee;
$success-color : #00ff80;
$card-padding : 10px;
$card-border-radius : 5px;
$button-padding : 15px;
$button-border-radius : 5px;
$table-header-background-color : #0065a3;
$table-header-color : #ffffff;
$spiner-background-color : #0065a3;
$drawer-tabs-background-color : #8000e281;
$border : 1px solid #9292924c;


// -------------------------------->
$mainColor : #012B49;
$boxShadow : 0px 0px 1px 1px #3c3c3c2b;