@import '../comman/Veriables.module.scss';

.appbarOpen {
    position: fixed;
    top: 2%;
    left: 20%;
    width: 78%;
    height: 12%;
    margin-left: 1%;
    margin-right: 1%;
    transition-duration: .2s;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    border-radius: 10px;

    .app_Section {
        display: flex;
        align-items: center;
    }

}

.appbarClose {
    background-color: #fff;
    position: fixed;
    left: 4%;
    top: 2%;
    width: 94%;
    height: 12%;
    margin-left: 1%;
    margin-right: 1%;
    transition-duration: .2s;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
    z-index: 2;

    .app_Section {
        display: flex;
        align-items: center;
    }

}