@import '../comman/Veriables.module.scss';


@mixin drawerMixin {
    background-color: #fff;
    height: 100vh;
    overflow-y: scroll;
    position: fixed;
    z-index: 1;
    transition-duration: .2s;
    padding-bottom: 20px;

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 15%;

        & img {
            width: auto;
        }
    }

    .actvie_link {
        color: #fff;
        background:#0C6A96;

        .active_icon {
            background: #fff!important;
        }
    }

    .non_actvie_link {
        color: #8E9BB9;
    }
}

.drawerOpen {
    @include drawerMixin;
    width: 20%;
}






.drawerClose {
    @include drawerMixin;
    width: 4%;
}