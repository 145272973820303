@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;300&family=Cabin&family=Shantell+Sans:wght@300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:'Gantari', sans-serif!important;
}

body {
    font-family: 'Gantari', sans-serif !important;
    background-color: #F4F7FF !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
    background-color: #E7F7FF !important;
}